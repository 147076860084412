import React from 'react';

import CloseIcon from '../../images/Close-2.svg';
import LinkedInIcon from '../../images/LINKEDIN.svg';
import { cn } from '../../utils';
import Icon from '../Icon/Icon';
import Image from '../Image/Image';
import TitleUnderlineParagraph from '../TitleUnderlineParagraph/TitleUnderlineParagraph';
import Paragraph from '../Typography/Paragraph/Paragraph';
import * as styles from './BusinessCardSlide.module.scss';
import IBusinessCardSlide from './BusinessCardSlide.type';

const BusinessCardSlide = (props: IBusinessCardSlide) => {
    return (
        <>
            <Image
                {...props.portrait}
                className={cn(styles.portrait, styles.slidePortrait)}
            />
            <div className={styles.content}>
                <TitleUnderlineParagraph
                    className={styles.slideName}
                    heading={props.name}
                    headingLevel="h3"
                    text={props.position}
                    textSize="medium"
                    underline="left"
                />
                <Paragraph size="medium" className={styles.bio}>
                    {props.bio}
                </Paragraph>
                <button
                    className={styles.close}
                    onClick={() => {
                        props.closeSlider();
                    }}
                >
                    <Icon
                        altText="close slider"
                        localFile={{
                            publicURL: CloseIcon,
                        }}
                    />
                </button>
                {props.linkedinProfile && props.linkedinProfile !== "#" && (
                    <a
                        href={props.linkedinProfile}
                        target="_blank"
                        className={styles.social}
                    >
                        <Icon
                            altText="LinkedIn icon"
                            localFile={{
                                publicURL: LinkedInIcon,
                            }}
                        />
                    </a>
                )}
            </div>
        </>
    );
};

export default BusinessCardSlide;
