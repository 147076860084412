import "swiper/css";

import React, { useEffect, useMemo, useRef, useState } from "react";
import SwiperCore, { EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import BusinessCardSlide from "../../components/BusinessCardSlide/BusinessCardSlide";
import Icon from "../../components/Icon/Icon";
import Image from "../../components/Image/Image";
import TitleUnderlineParagraph from "../../components/TitleUnderlineParagraph/TitleUnderlineParagraph";
import ArrowIcon from "../../images/Arrow-2.svg";
import { cn, scrollToSection } from "../../utils";
import * as styles from "./TeamMembers.module.scss";
import ITeamMembers from "./TeamMembers.type";

const TeamMembers = (props: ITeamMembers) => {
    const [swiper, setSwiper] = useState<SwiperCore>();
    const [showSlider, setShowSlider] = useState<boolean>(false);
    const [activeSlide, setActiveSlide] = useState<number>(0);
    const numberOfSlides = useMemo(() => props.teamMembers.length, []);
    const swiperRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        swiper?.slideTo(activeSlide);
    }, [activeSlide]);

    return (
        <section className={cn("js-scrollToSection", styles.section)}>
            <div className="container">
                <TitleUnderlineParagraph
                    className={styles.heading}
                    heading={props.heading}
                    headingLevel="h2"
                    text={props.description}
                    textSize="medium"
                />
                <div className={styles.inner} ref={swiperRef}>
                    <div
                        className={styles.sliderWrapper}
                        style={{ display: !showSlider ? "none" : "" }}
                    >
                        <Swiper
                            className={styles.slider}
                            modules={[EffectFade]}
                            effect="fade"
                            fadeEffect={{
                                crossFade: true,
                            }}
                            followFinger={false}
                            simulateTouch={false}
                            onSlideChange={(slide) =>
                                setActiveSlide(slide.activeIndex)
                            }
                            onInit={setSwiper}
                        >
                            {props.teamMembers.map((member) => (
                                <SwiperSlide
                                    key={member.name}
                                    className={styles.slide}
                                >
                                    <BusinessCardSlide
                                        {...member}
                                        closeSlider={() => setShowSlider(false)}
                                    />
                                </SwiperSlide>
                            ))}
                        </Swiper>
                        <div className={cn(styles.pagination, styles.slider)}>
                            <span className={styles.display}>
                                <span>
                                    {activeSlide < 9 && "0"}
                                    {activeSlide + 1}
                                </span>
                                {` / `}
                                {numberOfSlides < 10 && "0"}
                                {numberOfSlides}
                            </span>
                            <button
                                className={cn(
                                    styles.paginationBtn,
                                    styles.prevSlideBtn
                                )}
                                disabled={activeSlide === 0}
                                onClick={() => {
                                    setActiveSlide(activeSlide - 1);
                                }}
                            >
                                <Icon
                                    localFile={{ publicURL: ArrowIcon }}
                                    altText="next slide"
                                />
                            </button>
                            <button
                                className={cn(
                                    styles.paginationBtn,
                                    styles.nextSlideBtn
                                )}
                                onClick={() => {
                                    setActiveSlide(activeSlide + 1);
                                }}
                                disabled={activeSlide === numberOfSlides - 1}
                            >
                                <Icon
                                    localFile={{ publicURL: ArrowIcon }}
                                    altText="next slide"
                                />
                            </button>
                        </div>
                    </div>
                    <ul
                        className={styles.thumbnails}
                        style={{ display: showSlider ? "none" : "" }}
                    >
                        {props.teamMembers.map((member, slideIndex) => (
                            <li key={member.name} className={styles.member}>
                                <button
                                    className={styles.button}
                                    onClick={() => {
                                        setShowSlider(true);
                                        scrollToSection();
                                        setActiveSlide(slideIndex);
                                        window.innerWidth < 992 &&
                                            window.scrollBy(
                                                0,
                                                swiperRef.current?.getBoundingClientRect()
                                                    .top || 0 - 7
                                            );
                                    }}
                                >
                                    <Image
                                        {...member.portrait}
                                        className={styles.portrait}
                                    />
                                    <TitleUnderlineParagraph
                                        className={styles.name}
                                        heading={member.name}
                                        headingLevel="h3"
                                        text={member.position}
                                        textSize="medium"
                                        underline="left"
                                    />
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </section>
    );
};

export default TeamMembers;
