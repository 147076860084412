// extracted by mini-css-extract-plugin
export var button = "TeamMembers-module--button--4eb8c";
export var container = "TeamMembers-module--container--667e8";
export var display = "TeamMembers-module--display--c9af5";
export var heading = "TeamMembers-module--heading--4d0f5";
export var name = "TeamMembers-module--name--a3d4d";
export var nextSlideBtn = "TeamMembers-module--next-slide-btn--43cc5";
export var pagination = "TeamMembers-module--pagination--04922";
export var paginationBtn = "TeamMembers-module--pagination-btn--947c9";
export var portrait = "TeamMembers-module--portrait--f3041";
export var prevSlideBtn = "TeamMembers-module--prev-slide-btn--f9b3f";
export var section = "TeamMembers-module--section--12b2e";
export var slide = "TeamMembers-module--slide--f2cb3";
export var slider = "TeamMembers-module--slider--7e72e";
export var sliderWrapper = "TeamMembers-module--slider-wrapper--a66be";
export var thumbnails = "TeamMembers-module--thumbnails--dacee";